<template>
  <div
    class="bg-white py-10 px-6 -mx-6 mt-4 mb-10 md:flex md:items-center md:justify-between border-b border-gray-200"
  >
    <gm-page-container css="items-center flex-col">
      <div class="w-full flex items-center">
        <div class="min-w-0 flex-1 h-full justify-start text-left flex flex-col pr-72">
          <div class="flex gap-4 items-center">
            <img :src="avatar" class="h-16 w-16 rounded-full" />
            <div class="flex gap-2 flex-col">
              <h2
                class="text-2xl mb-0 font-semibold text-black sm:truncate sm:text-3xl sm:tracking-tight"
              >
                {{ greeting }}
              </h2>
              <div class="flex items-center text-gray-500">
                <BuildingOfficeIcon
                  class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {{ companyName }} <span class="text-xs mx-2 text-gray-300">&bull;</span>
                <span
                  class="text-xs bg-gray-200 text-gray-600 rounded-full px-2 py-0.5 capitalize"
                  >{{ userLevel }}</span
                >
              </div>
            </div>
          </div>

          <div v-if="haveSubTitle" class="text-md font-normal text-gray-500 mt-3">
            {{ subTitle }}
          </div>
        </div>
        <div class="mt-4 flex md:mt-0 md:ml-4 items-center">
          <gm-button secondary label="View Profile" href="/profile" />
        </div>
      </div>
    </gm-page-container>
  </div>
</template>

<script setup>
import { computed } from "vue";
import PageHeader from "../components/PageHeader.vue";
import useUserStore from "@/store/user";
import { BuildingOfficeIcon } from "@heroicons/vue/24/solid";

const userStore = useUserStore();

const avatar = computed(() => {
  if (userStore.avatar) {
    return userStore.avatar;
  }
  return "/img/avatars/default-avatar.png";
});

const userLevel = "admin";

const userLevelClass = computed(() => {
  if (userLevel === "admin") {
    return "bg-brandDarkBlue-50 text-brandDarkBlue-500";
  }

  return "bg-gray-500";
});

const companyName = computed(() => {
  if (userStore.company && userStore.company.name) {
    return userStore.company.name;
  }
  return null;
});

const greeting = computed(() => {
  // Calculate a greeting based on the user's first name and time of day
  const hour = new Date().getHours();
  let greetingTime = "Hello";

  if (hour >= 0 && hour < 4) {
    greetingTime = "Good evening";
  } else if (hour >= 4 && hour < 12) {
    greetingTime = "Good morning";
  } else if (hour >= 12 && hour < 18) {
    greetingTime = "Good afternoon";
  } else if (hour >= 18 && hour < 24) {
    greetingTime = "Good evening";
  }

  if (userStore.firstName) {
    return `${greetingTime}, ${userStore.firstName}`;

    // return `
    // <div class="flex gap-4 items-center">
    //   <img src="${avatar.value}" class="h-16 w-16 rounded-full" />
    //   <div class="flex flex-col gap-2">
    //     <div>${greetingTime}, ${userStore.firstName}</div>
    //     <div class="text-sm tracking-normal text-gray-600 flex gap-2">
    //       <span class="rounded-full px-2 py-0.5 bg-gray-200 flex items-center">${companyName.value}</span>
    //       <span class="uppercase  leading-0 items-center flex text-xs ${userLevelClass.value} rounded-full px-2 py-0.5">${userLevel}</span>
    //     </div>
    //   </div>
    // </div>`;
  }
  return "Welcome";
});
</script>
